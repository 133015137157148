import { getGuessStatuses} from '../../lib/statuses'
import { Cell } from './Cell'
import { unicodeSplit, solution } from '../../lib/words'

type Props = {
  guess: string
  isRevealing?: boolean
  isHardMode: boolean
}

export const CompletedRow = ({ guess, isRevealing, isHardMode }: Props) => {
  const statuses = getGuessStatuses(guess)
  const splitGuess = unicodeSplit(guess)
  
	var pointer1
	
	if (parseInt(guess.trim()) < parseInt(solution.trim())) pointer1 = "up.png"
	if (parseInt(guess.trim()) > parseInt(solution.trim())) pointer1 = "down.png"
	if (parseInt(guess.trim()) === parseInt(solution.trim())) pointer1 = "left.png"
	
	const alt1 = String(pointer1).replace(".png", "")
	
	const pointerclasses = 'w-8 h-14 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white'
      //'cell-fill-animation': isFilled,
      //'cell-reveal': shouldReveal,
  
  return (
	<div className="flex justify-center mb-1">
		<div className={pointerclasses}>
    	<div className="letter-container">
			
		</div>
		</div>
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          status={statuses[i]}
          position={i}
          isRevealing={isRevealing}
          isCompleted
        />
      ))}
	  
	  
	  
	  <div className={pointerclasses}>
    	<div className="letter-container">
			{isHardMode ? <br/>:<img max-width="80%" max-height="80%" object-fit="contain" src={pointer1} alt={alt1} />} 
		</div>
		</div>
    </div>
  )
}