import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const pointerclasses = 'w-8 h-14 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white'
const pointer1 = "up.png"
const pointer2 = "down.png"
const alt1 = String(pointer1).replace(".png", "")
const alt2 = String(pointer2).replace(".png", "")
	
export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the date in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the date. Use the space key at the start of the date to fill gaps for dates before 1000, and the - key for BCE dates. eg " 476", " -31". There could be up to three leading spaces (eg the year 5 CE)
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="1"
          status="correct"
        />
        <Cell value="0" />
        <Cell value="6" />
        <Cell value="6" />
		
		<div className={pointerclasses}>
			<div className="letter-container">
				<img max-width="80%" max-height="80%" object-fit="contain" src={pointer1} alt={alt1} />
			</div>
			</div>
		</div>
	  
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The digit 1 is in the date and in the correct spot. The correct date is later than 1066
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value=" " />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="4"
          status="present"
        />
        <Cell value="7" />
        <Cell value="6" />
		  <div className={pointerclasses}>
			<div className="letter-container">
				<img max-width="80%" max-height="80%" object-fit="contain" src={pointer2} alt={alt2} />
			</div>
			</div>
		</div>
	  <p className="text-sm text-gray-500 dark:text-gray-300">
        The digit 4 is in the date but in the wrong spot. The correct date is earlier than 476
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value=" " />
        <Cell value="-" />
        <Cell isRevealing={true} isCompleted={true} value="3" status="absent" />
        <Cell value="1" />
		<div className={pointerclasses}>
    	<div className="letter-container">
			<img max-width="80%" max-height="80%" object-fit="contain" src={pointer1} alt={alt1} />
		</div>
		</div>
      </div>
	  <p className="text-sm text-gray-500 dark:text-gray-300">
        The digit 3 is not in the date in any spot. The correct date is later than 31 BCE
      </p>
	
	  <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">The up/down arrows can be disabled by switching to Hard Mode in the settings</p>
	  
	  <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        Historic date guessing game by{' '}
        <a
          href="https://twitter.com/PeterChambers1"
          className="underline font-bold"
        >
          @PeterChambers1
        </a>
      , based on{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          react-wordle
        </a>, an open source version of the word guessing game we all know and
        love!{' '}
      </p>
    </BaseModal>
  )
}
